import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { currency } from '@extend/client-helpers'
import { COLOR } from '@helloextend/customers-ui'
import { bp } from '@extend/client-helpers'
import images from '../../../lib/images'

interface ItemCardProps {
  id: string
  imageUrl: string
  listPrice: number
  quantity: number
  title: string
}

const ItemCard: FC<ItemCardProps> = ({ imageUrl, listPrice, quantity, title }) => {
  const [isDefaultImage, setIsDefaultImage] = useState(false)

  useEffect(() => {
    if (!imageUrl) {
      setIsDefaultImage(true)
    }
  }, [imageUrl])

  const handleImageError = (): void => {
    setIsDefaultImage(true)
  }

  return (
    <Wrapper>
      {isDefaultImage ? (
        <DefaultImage>
          <img
            src={images.defaultCarouselImage}
            height="27.5px"
            width="34.38"
            alt="Placeholder default"
          />
        </DefaultImage>
      ) : (
        <ProductImage>
          <img src={imageUrl} alt={title} onError={handleImageError} />
        </ProductImage>
      )}
      <DetailsWrapper>
        <Title>{title}</Title>
        <QuantityPriceWrapper>
          <Price>{currency.format(listPrice)}</Price>
          QTY: {quantity}
        </QuantityPriceWrapper>
      </DetailsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  height: 55,
  marginBottom: 8,
})

const DefaultImage = styled.div({
  backgroundColor: COLOR.NEUTRAL[100],
  borderRadius: 1,
  width: 55,
  height: 55,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 8,
})

const ProductImage = styled.div({
  alignItems: 'center',
  width: 55,
  display: 'flex',
  marginRight: 8,
  '> img': {
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    width: 'auto',
  },
})

const DetailsWrapper = styled.div({
  alignItems: 'center',
  width: '100%',
})

const Title = styled.div({
  fontSize: 14,
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: 16,
  [bp.md]: {
    fontSize: 16,
  },
})

const QuantityPriceWrapper = styled.div({
  display: 'flex',
  width: '50%',
  fontSize: 10,
  fontWeight: 400,
  color: COLOR.NEUTRAL[800],
  [bp.md]: {
    fontSize: 12,
  },
})

const Price = styled.div({
  marginRight: 16,
})

export type { ItemCardProps }
export { ItemCard }
