import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { Button, COLOR } from '@extend/zen'
import type { ShipmentProductsListProps } from './shipment-selector-types'

const PRODUCT_LIST_SIZE = 3

export const ShipmentProductsList: FC<ShipmentProductsListProps> = ({ products }) => {
  const [isExpandedList, setIsExpandedList] = useState<boolean>(false)

  const expandList = (): void => {
    setIsExpandedList(true)
  }

  const viewableProducts = useMemo(() => {
    return products.slice(0, isExpandedList ? undefined : PRODUCT_LIST_SIZE)
  }, [products, isExpandedList])

  return (
    <ShipmentProducts>
      {Boolean(products) && (
        <ProductsList>
          {viewableProducts.map(({ imageUrl, title }, id) => {
            return (
              <ProductListItem data-cy={`shipment-product-${id}`} key={title}>
                {imageUrl && (
                  <ImageWrapper data-cy="shipment-product-image">
                    <img src={imageUrl} alt={title} />
                  </ImageWrapper>
                )}
                <ProductName data-cy="shipment-product-name">{title}</ProductName>
              </ProductListItem>
            )
          })}
          {!isExpandedList && products.length > PRODUCT_LIST_SIZE && (
            <Button
              onClick={expandList}
              emphasis="low"
              color="neutral"
              type="button"
              text={`Show all ${products.length} items`}
            />
          )}
        </ProductsList>
      )}
    </ShipmentProducts>
  )
}

const ShipmentProducts = styled.div({
  padding: 16,
})

const ProductsList = styled.div({
  display: 'grid',
  grid: 'auto-flow / 1fr',
  gap: 8,
})

const ProductListItem = styled.div({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
})

const ProductName = styled.p({
  margin: 0,
  fontSize: 13,
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  [bp.lg]: {
    fontSize: 16,
    lineHeight: '24px',
  },
})

const ImageWrapper = styled.div({
  minWidth: 36,
  width: 36,
  height: 36,
  backgroundColor: COLOR.NEUTRAL[100],
  img: {
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    width: '100%',
  },
})
