import type { Dispatch, FC, SetStateAction } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { bp, Text } from '@helloextend/customers-ui'
import { COLOR } from '@extend/zen'
import { useMeasureRef, useScreenSize } from '@helloextend/client-hooks'

export interface TestimonialProps {
  name: JSX.Element | string
  comment: JSX.Element | string
  setMaxHeight: Dispatch<SetStateAction<number>>
  maxHeight: number
  isLongestReview?: boolean
}

const Testimonial: FC<TestimonialProps> = ({
  name,
  comment,
  setMaxHeight,
  maxHeight,
  isLongestReview,
}) => {
  const { measureRef, height } = useMeasureRef()
  const screenSize = useScreenSize()

  useEffect(() => {
    const totalVerticalPadding = screenSize === 'small' ? 46 : 68

    if (height > 0) {
      setMaxHeight(height + totalVerticalPadding)
    }
  }, [height, isLongestReview, name, screenSize, setMaxHeight])
  return (
    <BubbleWrapper>
      <Bubble className="bubble" height={maxHeight}>
        <Text>
          <Arrow />
          <span ref={measureRef}>{comment}</span>&nbsp;
        </Text>
        <Author>
          <Text>{name}</Text>
        </Author>
      </Bubble>
    </BubbleWrapper>
  )
}

const BubbleWrapper = styled.div({
  height: 'auto',
  position: 'relative',
  [bp.mobile]: {
    padding: '0px 0px',
    marginLeft: '15px',
  },
})

const Bubble = styled.div<{ height: number }>(
  {
    background: COLOR.WHITE,
    color: COLOR.NEUTRAL[600],
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: '20px 36px',
    lineHeight: '30px',
    marginRight: 24,
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
    position: 'absolute',
    fontSize: 16,
    borderRadius: 14,
    height: 'auto',
    [bp.mobile]: {
      marginRight: 44,
      width: '320px',
      fontSize: 19,
      marginLeft: 5,
      padding: '20px 0px 0px 20px',
    },
    [bp.desktop]: {
      fontSize: 20,
      width: 'calc(100% - 40px)',
      padding: '20px 20px 0px 20px',
      lineHeight: '30px',
    },
  },
  ({ height }) => ({
    height,
  }),
)

const Arrow = styled.div({
  borderStyle: 'solid',
  position: 'absolute',
  borderColor: `${COLOR.NEUTRAL[300]} transparent transparent transparent`,
  borderWidth: '21px 21px 0px 21px',
  bottom: -21,
  '&:after': {
    borderColor: `${COLOR.WHITE} transparent transparent transparent`,
    borderStyle: 'solid',
    borderWidth: '20px 20px 0px 20px',
    bottom: '1px',
    content: '""',
    position: 'absolute',
    left: -20,
  },
})

const Author = styled.div({
  bottom: -59,
  left: 45,
  position: 'absolute',
  fontWeight: 600,
  fontSize: 18,
  color: COLOR.BLACK,
  [bp.tablet]: {
    fontSize: 20,
    left: 49,
    bottom: -61,
  },
  [bp.desktop]: {
    left: 45,
    bottom: -63,
    fontSize: 22,
  },
})
export { Testimonial }
