import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { ShippingProtectionCover } from './shipping-protection-cover'
import { LearnMore } from './learn-more'
import { WhyExtend } from './why-extend'
import { Testimonials } from './testimonials'
import { HowShippingProtectionWorks } from './how-shipping-protection-works'
import { Charity } from './charity'

interface ContentBodyProps {
  shippingProtectionContent:
    | {
        coverageHeader?: string
        coverageItem1Description?: string
        coverageItem1Icon?: string
        coverageItem1Title?: string
        coverageItem2Description?: string
        coverageItem2Icon?: string
        coverageItem2Title?: string
        coverageItem3Description?: string
        coverageItem3Icon?: string
        coverageItem3Title?: string
        learnMoreBody?: string
        learnMoreButtonText?: string
        learnMoreHeader?: string
        processExplainedHeader?: string
        processExplainedImage?: string
        processExplainedStep1Body?: string
        processExplainedStep1Header?: string
        processExplainedStep1Label?: string
        processExplainedStep2Body?: string
        processExplainedStep2Header?: string
        processExplainedStep2Label?: string
        processExplainedStep3Body?: string
        processExplainedStep3Header?: string
        processExplainedStep3Label?: string
        testimonial1AuthorName?: string
        testimonial1Body?: string
        testimonial2AuthorName?: string
        testimonial2Body?: string
        testimonial3AuthorName?: string
        testimonial3Body?: string
        testimonialsHeader?: string
        valuePropositionBody?: string
        valuePropositionHeader?: string
        valuePropositionImage?: string
      }
    | undefined
  charityContentEnabled: boolean
  charityContent:
    | {
        charityPlanDetailsHeadline?: string
        charityPlanDetailsSubheadline?: string
        charityPlanDetailsImage?: string
        charityPlanDetailsDisclosureText?: string
      }
    | undefined
}

const ContentBody: FC<ContentBodyProps> = (props) => {
  const { shippingProtectionContent, charityContentEnabled, charityContent } = props
  return (
    <ContentBodyWrapper>
      <ShippingProtectionCover {...shippingProtectionContent} />
      {charityContentEnabled ? <Charity {...charityContent} /> : null}
      <WhyExtend {...shippingProtectionContent} />
      <LearnMore {...shippingProtectionContent} />
      <Testimonials {...shippingProtectionContent} />
      <HowShippingProtectionWorks {...shippingProtectionContent} />
    </ContentBodyWrapper>
  )
}
const ContentBodyWrapper = styled.div({
  marginTop: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export { ContentBody }
