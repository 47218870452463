import { customLogger } from '@extend/client-helpers'
import { v4 as uuid } from 'uuid'
import fetch from 'cross-fetch'
import type { CustomRequestInit } from './types'

export const RETRY_CODES = [408, 500, 502, 503, 504, 522, 524]

export const IDEMPOTENCY_HEADER = 'X-Idempotency-Key'

export async function fetchRetry(options: FetchRetryOptions): Promise<Response> {
  const { requestInit, url } = options

  const idempotencyKey = uuid()

  requestInit.headers[IDEMPOTENCY_HEADER] = idempotencyKey

  customLogger.info('Using retry fetch', { idempotencyKey, url })

  async function recursiveFetch(recursiveOptions: FetchRetryOptions): Promise<Response> {
    const { retries = 3, backoff = 300 } = recursiveOptions

    try {
      const response = await fetch(url, requestInit)

      if (retries === 0) return response

      const { status } = response

      if (RETRY_CODES.includes(status)) {
        await new Promise((resolve) => {
          setTimeout(resolve, backoff)
        })

        await recursiveFetch({
          url,
          requestInit,
          retries: (retries > 0 ? retries - 1 : 0) as UpToFive,
          backoff: backoff * 2,
        })
      }

      return response
    } catch (err) {
      if (err instanceof Error) {
        customLogger.error('Problem retrying', { url, err: err.message, stack: err.stack })
      }
      throw err
    }
  }

  return recursiveFetch(options)
}

interface FetchRetryOptions {
  url: string
  requestInit: CustomRequestInit
  retries?: UpToFive
  backoff?: number
}

type UpToFive = 0 | 1 | 2 | 3 | 4 | 5
