import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { COLOR } from '@extend/zen'
import { bp, Text } from '@helloextend/customers-ui'
import { v4 as uuid } from 'uuid'
import { useScreenSize } from '@helloextend/client-hooks'
import { Testimonial } from './testimonial'
import { svgs, SHIPPING_PROTECTION_TESTIMONIALS } from '../../../../lib/assets'
import { BackButton, NextButton } from '../../../../components/common'

interface TestimonialsProps {
  testimonialsHeader?: string
  testimonial1Body?: string
  testimonial1AuthorName?: string
  testimonial2Body?: string
  testimonial2AuthorName?: string
  testimonial3Body?: string
  testimonial3AuthorName?: string
}

const Testimonials: FC<TestimonialsProps> = ({
  testimonialsHeader,
  testimonial1AuthorName,
  testimonial1Body,
  testimonial2AuthorName,
  testimonial2Body,
  testimonial3AuthorName,
  testimonial3Body,
}) => {
  const [maxHeight, setMaxHeight] = useState(0)

  const screenSize = useScreenSize()
  const isSmallScreen = screenSize === 'small'
  const CARD_WIDTH = 301
  const TOTAL_BUBBLE_HEIGHT = maxHeight + 68

  const BUBBLE_MARGIN_BOTTOM = isSmallScreen || screenSize === 'medium' ? 69 : 47

  const visibleSlidesCount = (): number => {
    if (screenSize === 'small') return 1
    if (screenSize === 'medium') return 2
    if (screenSize === 'large') return 2
    return 3
  }

  const testimonialsList = useMemo(() => {
    return [
      {
        name: testimonial1AuthorName || SHIPPING_PROTECTION_TESTIMONIALS[0].name,
        comment: testimonial1Body || SHIPPING_PROTECTION_TESTIMONIALS[0].comment,
      },
      {
        name: testimonial2AuthorName || SHIPPING_PROTECTION_TESTIMONIALS[1].name,
        comment: testimonial2Body || SHIPPING_PROTECTION_TESTIMONIALS[1].comment,
      },
      {
        name: testimonial3AuthorName || SHIPPING_PROTECTION_TESTIMONIALS[2].name,
        comment: testimonial3Body || SHIPPING_PROTECTION_TESTIMONIALS[2].comment,
      },
    ]
  }, [
    testimonial1AuthorName,
    testimonial1Body,
    testimonial2AuthorName,
    testimonial2Body,
    testimonial3AuthorName,
    testimonial3Body,
  ])

  return (
    <TestimonialsWrapper>
      <SectionTitle>
        <Text>{testimonialsHeader || 'Hear It From Our Happy Customers'}</Text>
      </SectionTitle>
      <CarouselProviderWrapper
        visibleSlides={visibleSlidesCount()}
        totalSlides={testimonialsList.length}
        step={visibleSlidesCount()}
        naturalSlideWidth={CARD_WIDTH}
        naturalSlideHeight={TOTAL_BUBBLE_HEIGHT + BUBBLE_MARGIN_BOTTOM}
        touchEnabled={isSmallScreen}
        dragEnabled={isSmallScreen}
        height={TOTAL_BUBBLE_HEIGHT + BUBBLE_MARGIN_BOTTOM}
        bubbleheight={TOTAL_BUBBLE_HEIGHT}
      >
        {!isSmallScreen && (
          <BackButton className="back-btn">
            <svgs.BackArrow />
          </BackButton>
        )}
        <SliderContainer height={TOTAL_BUBBLE_HEIGHT} isSmallScreen={isSmallScreen}>
          <SliderWrapper aria-label="slider">
            {testimonialsList.map((testimonial, i) => {
              return (
                <Slide index={i} key={uuid()}>
                  <Testimonial {...testimonial} setMaxHeight={setMaxHeight} maxHeight={maxHeight} />
                </Slide>
              )
            })}
          </SliderWrapper>
        </SliderContainer>

        {!isSmallScreen && (
          <NextButton className="next-btn">
            <svgs.RightChevron />
          </NextButton>
        )}
      </CarouselProviderWrapper>
    </TestimonialsWrapper>
  )
}

const SectionTitle = styled.h2({
  fontSize: 40,
  fontWeight: 700,
  fontFamily: 'Nunito Sans',
  textAlign: 'center',
  lineHeight: '48px',
  paddingTop: 65,
  paddingBottom: 29,
  margin: '0 32px',
  [bp.tablet]: {
    paddingBottom: 34,
  },
  [bp.desktop]: {
    fontSize: 39,
    paddingTop: 47,
    paddingBottom: 43,
  },
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '32px',
    fontWeight: 800,
  },
})

const CarouselProviderWrapper = styled(CarouselProvider)<{ height: number; bubbleheight: number }>(
  {
    display: 'flex',
    width: '100%',
    height: '50%',
    alignItems: 'flex-start',
    margin: '0 auto',
    '& svg': {
      width: 13,
    },
    '& button': {
      height: 'auto',
      width: 34,
      alignSelf: 'center',
    },
    '& .next-btn': {
      paddingRight: 32,
    },
    '& .back-btn': {
      paddingLeft: 32,
    },
    [bp.mobile]: {
      height: '100%',
      marginBottom: '32px',
    },
  },
  ({ height, bubbleheight }) => ({
    height,
    '& button': {
      transform: `translateY(-${bubbleheight / 3}px)`,
      [bp.desktop]: {
        transform: `translateY(-${bubbleheight / 2}px)`,
      },
      [bp.desktopMd]: {
        transform: `translateY(-${bubbleheight / 3}px)`,
      },
    },
  }),
)

const TestimonialsWrapper = styled.div({
  marginTop: 70,
  position: 'relative',
  background: COLOR.NEUTRAL[100],
  [bp.mobile]: {
    width: 'calc(100% + 40px)',
    marginTop: 0,
  },
  [bp.desktop]: {
    width: 'calc(100% + 240px)',
  },
})

interface SliderContainerProps {
  isSmallScreen: boolean
  height: number
}

const SliderContainer = styled.div<SliderContainerProps>(
  {
    flex: 1,
    margin: '0px auto',
    width: '100%',
    [bp.tablet]: {
      width: '100%',
    },
  },
  (props) => ({
    height: props.height,
  }),
)

const SliderWrapper = styled(Slider)({
  overflowX: 'hidden',
  height: '100%',
  '*': {
    outlineStyle: 'none !important',
  },
  [bp.mobile]: {
    width: '100%',
    padding: '0px 40px 0px 0px',
  },
  [bp.tablet]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
  },
})

export { Testimonials }
