import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import type { Claim, ContractsV2GetResponse, ServiceOrder } from '@helloextend/extend-api-client'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@helloextend/customers-ui'

import { calcActiveStep, isClaimCompleted } from '../../utils/stepper-helper'
import { getIsRepairClaim } from '../../utils/get-is-repair'
import { images } from '../../lib/assets'
import { ClaimStepper } from '../../components/claim-stepper'
import { MatchColorToStatus } from '../contract-details-page/match-color-to-status'

export interface ClaimStepperDetailProps {
  claim: Claim
  contract?: ContractsV2GetResponse
  serviceOrder?: ServiceOrder[]
}

export const ClaimProgressStatus: Record<string, string> = {
  review: 'In review',
  denied: 'Denied',
  fulfilled: 'Fulfilled',
  approved: 'In progress',
}

const ClaimStepperDetail: FC<ClaimStepperDetailProps> = ({ claim, contract, serviceOrder }) => {
  const { status } = claim
  const serviceType = serviceOrder?.length ? serviceOrder[0] : claim
  const isRepair = getIsRepairClaim(serviceType)
  const currentStepper = calcActiveStep(serviceOrder, isRepair)
  const [currentStep, setCurrentStep] = useState<number>(currentStepper)
  const setActiveStep = (active: number): void => {
    setCurrentStep(active)
  }

  const handlePreviousStep = (): void => {
    setCurrentStep(currentStep - 1)
  }

  const handleNextStep = (): void => {
    setCurrentStep(currentStep + 1)
  }

  const isRenderClaimProgressButtons =
    !['review', 'denied'].includes(status) && contract?.planDetails?.category !== 'wheel_tire'

  return (
    <ClaimStepContainer>
      <ClaimStepperSideMenu>
        <ClaimStepperTitle>Claim Progress</ClaimStepperTitle>
        <MatchColorToStatus
          status={status}
          isClaimCompleted={isClaimCompleted(serviceOrder)}
          claim={claim}
        />
        {isRenderClaimProgressButtons && (
          <ClaimProgressButtons>
            <Button onClick={handlePreviousStep}>
              <img src={images.leftArrow} alt="left arrow" />
            </Button>
            <Button onClick={handleNextStep}>
              <img src={images.rightArrow} alt="right arrow" />
            </Button>
          </ClaimProgressButtons>
        )}
      </ClaimStepperSideMenu>
      <ClaimStepperWrapper>
        <StyledClaimStepper
          hasDetails
          {...{ contract, claim, serviceOrder, currentStep, isRepair, setActiveStep }}
        />
      </ClaimStepperWrapper>
    </ClaimStepContainer>
  )
}

const ClaimStepContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 375,
  marginLeft: -20,
  borderRadius: '0px 16px 16px 0px',
  backgroundColor: COLOR.BLUE[100],
  [bp.lg]: {
    gap: 200,
    width: 'calc(100% + 120px)',
    height: 363,
    flexDirection: 'row',
    marginLeft: -120,
  },
  [bp.md]: {
    gap: 100,
    marginLeft: -120,
    flexDirection: 'row',
    width: 'calc(100% + 120px)',
  },
  [bp.maxWidthMd]: {
    borderRadius: 0,
    width: '100vw',
  },
})

const ClaimStepperSideMenu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '30px 0 0 120px',
  [bp.maxWidthMd]: {
    margin: '24px 0 0 20px',
  },
})

const ClaimStepperTitle = styled.div({
  marginBottom: 16,
  fontSize: 28,
  lineHeight: '36px',
  [bp.maxWidthMd]: {
    marginBottom: 8,
    fontSize: 20,
    lineHeight: '28px',
  },
})

const ClaimProgressButtons = styled.div({
  [bp.md]: {
    display: 'flex',
    marginTop: 150,
    justifyContent: 'space-between',
    width: 98,
    height: 44,
  },
  display: 'none',
})

const ClaimStepperWrapper = styled.div({
  marginLeft: 0,
  [bp.maxWidthMd]: {
    marginLeft: 20,
  },
})

const Button = styled.div({
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledClaimStepper = styled(ClaimStepper)({
  margin: '40px 0',
  [bp.maxWidthMd]: {
    margin: '24px 0',
  },
})

export { ClaimStepperDetail }
