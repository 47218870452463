import type { FC } from 'react'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { SplashScreen } from '@helloextend/component-commons'
import { currency } from '@extend/client-helpers'
import type { ServiceOrderItem } from '@helloextend/extend-api-client/src/models/service-order'
import { COLOR, ContentLayout } from '@helloextend/customers-ui'

import images from '../../../lib/images'
import type { ContractWithPlan } from '../../../reducers/contracts/by-id'
import type { ItemCardProps } from './item-card'
import { ItemCard } from './item-card'

interface ShippingProtectionProductDetailsProps {
  contract: ContractWithPlan
  accessToken: string
  serviceOrder: ServiceOrder
}

const ShippingProtectionProductDetails: FC<ShippingProtectionProductDetailsProps> = ({
  contract,
  accessToken,
  serviceOrder,
}) => {
  const [isSeeMoreExpanded, setIsSeeMoreExpanded] = useState(false)

  if (!serviceOrder || !serviceOrder.items) {
    return (
      <ContentLayout>
        <SplashScreen data-cy="splash-screen" />
      </ContentLayout>
    )
  }

  const cards = getItems(serviceOrder.items).map((item) => {
    return <ItemCard {...item} key={item.id} />
  })

  const replacementValue = currency.format(
    serviceOrder.totalItemsValue?.amount,
    serviceOrder.totalItemsValue?.currencyCode,
  )

  return (
    <ShippingProtectionProductDetailsSection>
      <ItemList>
        <DetailTitle>Replacement Item(s): </DetailTitle>
        <ItemCardsWrapper>{isSeeMoreExpanded ? cards : cards.slice(0, 3)}</ItemCardsWrapper>
        {serviceOrder.items.length > 3 && (
          <SeeMoreButton
            className="see-more-btn"
            onClick={() => setIsSeeMoreExpanded(!isSeeMoreExpanded)}
          >
            {isSeeMoreExpanded ? 'See Less' : 'See More Items'}
          </SeeMoreButton>
        )}
      </ItemList>
      <DetailsList>
        <DetailItem order={1}>
          <DetailTitle>What&apos;s Covered: </DetailTitle>
          <Detail data-cy="covered-order">
            {contract.sellerName} Order #{contract.transactionId}
          </Detail>
        </DetailItem>
        <DetailItem order={2}>
          <DetailTitle>Contract ID: </DetailTitle>
          <Detail>
            <ContractLink
              href={`/warranty_terms?contractId=${contract.id}&accessToken=${accessToken}`}
              target="_blank"
            >
              {contract.id}
            </ContractLink>
          </Detail>
        </DetailItem>
        <DetailItem order={3}>
          <DetailTitle>Replacement Value: </DetailTitle>
          <Detail data-cy="replacement-value">{replacementValue}</Detail>
        </DetailItem>
        <DetailItem order={4}>
          <DetailTitle>Date Purchased: </DetailTitle>
          <Detail data-cy="date-purchased">
            {dayjs(contract.transactionDate).format('MMM DD, YYYY')}
          </Detail>
        </DetailItem>
      </DetailsList>
    </ShippingProtectionProductDetailsSection>
  )
}

export const getItems = (items: ServiceOrderItem[]): ItemCardProps[] => {
  const itemMap: Record<string, string> = {}
  items.forEach((item) => {
    if (item.referenceItemType === 'product') {
      itemMap[item.referenceItemId] = item.title
    }
  })
  const cardItems = items.map((item) => {
    const cardItem = { ...(item as ItemCardProps) }
    if (item.referenceItemType === 'extended_warranty') {
      cardItem.title = `${item.title} for ${itemMap[item.productId]}`
      cardItem.imageUrl = images.extendIcon
    }

    return cardItem
  })
  return cardItems
}

const ContractLink = styled.a({
  color: COLOR.BLUE[700],
  '&:hover': {
    textDecoration: 'underline',
  },
  '& svg': {
    width: 13,
    transform: 'translate(10px, 3px)',
  },
  '&:visited': { color: COLOR.BLUE[700] },
})

const ShippingProtectionProductDetailsSection = styled.section({
  marginTop: 24,
  marginBottom: 30,
  [bp.md]: {
    display: 'flex',
    margin: '48px 50px',
  },
})

const ItemCardsWrapper = styled.div({
  marginBottom: 24,
})

const DetailsList = styled.ul({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  [bp.md]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    height: '182px',
  },
})

const DetailItem = styled.li<{
  order: number
}>(({ order }) => ({
  order,
  [bp.md]: {
    marginTop: 0,
    width: '48%',
  },
}))

const ItemList = styled.div({
  [bp.md]: {
    width: '48%',
  },
})

const DetailTitle = styled.h3({
  position: 'relative',
  fontSize: 14,
  fontWeight: 700,
  height: 19,
  lineHeight: '22px',
  margin: 0,
  marginTop: 20,
  marginBottom: 8,
  textTransform: 'uppercase',
  '& svg': {
    transform: 'translate(3px, 2px)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '& svg:hover + div': {
    display: 'block',
  },
  [bp.md]: {
    marginTop: 0,
    fontSize: 16,
  },
})

const Detail = styled.div({
  fontSize: 14,
  lineHeight: '19px',
  padding: 0,
  margin: 0,
  [bp.md]: {
    fontSize: 16,
  },
})

const SeeMoreButton = styled.a({
  cursor: 'pointer',
  color: COLOR.BLUE[700],
  '&:visited': { color: COLOR.BLUE[700] },
})

export type { ShippingProtectionProductDetailsProps }
export { ShippingProtectionProductDetails }
