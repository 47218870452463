import { COLOR, Dots, Modal } from '@extend/zen'
import { useGetStoreConsumerQuery } from '@helloextend/extend-api-rtk-query'
import React from 'react'
import type { ContractsV2GetResponse } from '@helloextend/extend-api-client'
import { formatPhoneNumberLocal } from '@extend/client-helpers'
import { isMobile } from 'react-device-detect'
import type { ContractsSearchIndividual } from '../types/contract'
import styled from '@emotion/styled'

interface ContactMerchantModalProps {
  onDismissRequest: () => void
  contract: ContractsSearchIndividual | ContractsV2GetResponse
}

const ContactMerchantModal: React.FC<ContactMerchantModalProps> = ({
  onDismissRequest,
  contract,
}) => {
  const { data: store, isFetching: isFetchingStoreInfo } = useGetStoreConsumerQuery({
    storeId: contract.sellerId,
    includeDeleted: 'true',
  })

  return (
    <Modal
      heading="Contact merchant to file a claim"
      onDismissRequest={() => onDismissRequest()}
      primaryButtonProps={{
        onClick: () => onDismissRequest(),
        text: 'Close',
        emphasis: 'medium',
      }}
      data-cy="contact-merchant-modal"
      isMobile={isMobile}
    >
      {isFetchingStoreInfo ? (
        <Dots isCenteredInParent={true} speed="fast" data-cy="dots" />
      ) : (
        <>
          To file a claim for {contract.sellerName} products, you&apos;ll need to contact{' '}
          {contract.sellerName} Customer Service via:
          <ListContainer>
            <ul>
              {store?.supportContact?.phoneNumber?.number && (
                <li data-cy="phoneListItem">
                  Phone: {formatPhoneNumberLocal(store.supportContact.phoneNumber.number)}
                </li>
              )}
              {store?.supportContact?.email && (
                <li data-cy="emailListItem">Email: {store.supportContact.email}</li>
              )}

              {store?.supportContact?.url && (
                <li data-cy="websiteListItem">
                  Website:{' '}
                  <SupportContactLink
                    href={store.supportContact.url}
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    {store.supportContact.url}
                  </SupportContactLink>
                </li>
              )}
            </ul>
          </ListContainer>
        </>
      )}
    </Modal>
  )
}

const SupportContactLink = styled.a({
  color: COLOR.BLUE[800],
})

const ListContainer = styled.div({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  textAlign: 'left',
  '& li': {
    marginLeft: 24,
  },
})

export default ContactMerchantModal
