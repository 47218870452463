import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { BrandedLoader, COLOR } from '@extend/zen'

interface SplashScreenProps {
  'data-cy'?: string
  zIndex?: number
}

export const SplashScreen: FC<SplashScreenProps> = ({ 'data-cy': dataCy, zIndex }) => {
  return (
    <Splash data-cy={dataCy} zIndex={zIndex}>
      <BrandedLoader type="wordmark" data-cy={dataCy && `${dataCy}:branded-loader`} />
    </Splash>
  )
}

const Splash = styled.div<{
  zIndex?: number
}>(({ zIndex }) => ({
  position: 'fixed',
  zIndex,
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  display: 'grid',
  placeContent: 'center',
  backgroundColor: COLOR.WHITE,
}))
