import type { FC } from 'react'
import React from 'react'
import { bp, Text } from '@helloextend/customers-ui'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { images } from '../../../../lib/assets'

interface ContentHeaderProps {
  bannerHeader?: string
  bannerBody?: string
  shippingOffersBannerImage?: string
}

const ContentHeader: FC<ContentHeaderProps> = ({
  bannerBody,
  bannerHeader,
  shippingOffersBannerImage,
}) => {
  return (
    <ContentHeaderWrapper>
      <ProtectionWrapper>
        <ProtectionMainTitle data-cy="main-title">
          <Text>{bannerHeader || 'Extend Shipping Protection'}</Text>
        </ProtectionMainTitle>
        <ProtectionDescription>
          <Text>
            {bannerBody ||
              'Give yourself added peace of mind when online shopping. With Shipping Protection by Extend™, your packages are protected from loss, theft, and damage until they arrive safely in your hands.'}
          </Text>
        </ProtectionDescription>
      </ProtectionWrapper>
      <ShippingProtectionImg
        src={shippingOffersBannerImage || images.shippingProtectionMobile}
        alt="Shipping Protection"
      />
    </ContentHeaderWrapper>
  )
}

const ContentHeaderWrapper = styled.div({
  [bp.mobile]: {
    height: 170,
    flexDirection: 'column',
  },
  [bp.desktop]: {
    height: 411,
    flexDirection: 'row',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ProtectionWrapper = styled.div({
  [bp.mobile]: {
    marginBottom: '20px',
  },
})

const ProtectionMainTitle = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 52,
    lineHeight: '57px',
    fontWeight: 600,
    paddingBottom: 16,
    width: '100%',
    textAlign: 'left',
  },
})

const ProtectionDescription = styled.div({
  [bp.mobile]: {
    fontSize: 18,
    lineHeight: '30px',
    fontWeight: 400,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 400,
    width: '89%',
    textAlign: 'left',
  },
  color: COLOR.NEUTRAL[700],
})

const ShippingProtectionImg = styled.img({
  [bp.desktop]: {
    width: '36%',
    transform: 'none',
  },
})

export { ContentHeader }
