/* eslint-disable no-nested-ternary */
import type { FC } from 'react'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import {
  Badge,
  Button,
  Popover,
  usePopover,
  COLOR,
  MenuButtonItem,
  More,
  OpenInNew,
  ArrowDropDownClose,
  ArrowDropDown,
} from '@extend/zen'
import {
  useGetMerchantServicingSettingsQuery,
  usePrecheckQuery,
} from '@helloextend/extend-api-rtk-query'
import { images } from '../../../lib/assets'
import {
  formatDate,
  getDisplayDetails,
  getIsClaimInProgress,
  missingProfileFieldTooltip,
  mapContractStatusBadge,
} from '../../../lib/helper-functions'
import { logEvent } from '../../../analytics'
import type { ShippingProtectionContract } from '../../../types/contract'

export interface ShippingProtectionCardProps {
  contract: ShippingProtectionContract
  onFileClaimClick: (handlerArgs: {
    contractId: string
    lineItemId?: string
    isMerchantOwnedIntake?: boolean
  }) => void
  isMissingRequiredProfileField?: boolean
}

const statusColorMap: Record<string, 'red' | 'blue' | 'green' | 'yellow' | 'neutral'> = {
  Active: 'green',
  Cancelled: 'red',
  Refunded: 'red',
  Fulfilled: 'neutral',
  Expired: 'neutral',
  Other: 'neutral',
}

const ShippingProtectionCard: FC<ShippingProtectionCardProps> = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const history = useHistory()
  const { locale } = useIntl()
  const [isViewAllExpanded, setIsViewAllExpanded] = useState(false)
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const { sellerName, purchaseDate, productsList, trackingInfo } = contract

  const handleContactSupport = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Contact Support')
    history.push(`/${locale}/contact`)
  }

  const handleReviewTerms = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Review Terms')
    window.open('https://www.extend.com/shipping-protection-guide')
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = images.fallbackImage
  }

  const { isClaimFilingDisabled, fileClaimTooltip, subBadgeText } = getDisplayDetails({
    precheck,
    contract,
  })

  const hasManyProducts = productsList?.length > 3
  let productsToShow = 3
  const hasTrackingUrl =
    !!contract.trackingInfo[0]?.trackingUrl || !!contract.trackingInfo[0]?.requestTrackingUrl

  return (
    <Wrapper data-contract-id={contract.id} data-cy="shipping-protection-card">
      <Header>
        <HeaderInfo>
          {!isLoading && (
            <BadgeWrapper>
              <Badge
                text="Shipping Protection"
                details={mapContractStatusBadge(contract.status)}
                color={statusColorMap[mapContractStatusBadge(contract.status)]}
                data-cy="sp-card-badge"
              />
              {getIsClaimInProgress(precheck, contract.productsList) && (
                <ClaimInProgressWrapper>
                  <Badge
                    emphasis="low"
                    text={subBadgeText || 'Claim in Progress'}
                    data-cy="sp-card-claim-progress-badge"
                    color="green"
                  />
                </ClaimInProgressWrapper>
              )}
            </BadgeWrapper>
          )}
          <HeaderTitle data-cy="sp-card-header-title">{`Order from ${sellerName}`}</HeaderTitle>
          <HeaderDetails data-cy="sp-card-header-details">{`${
            trackingInfo?.length ?? 0
          } shipment(s) · ${productsList?.length} item(s) · Purchased ${formatDate(
            purchaseDate,
          )}`}</HeaderDetails>
        </HeaderInfo>
        <HeaderButtons>
          <Button
            emphasis="medium"
            text="File a Claim"
            isDisabled={!precheck || isClaimFilingDisabled || isMissingRequiredProfileField}
            tooltip={isMissingRequiredProfileField ? missingProfileFieldTooltip : fileClaimTooltip}
            onClick={() =>
              onFileClaimClick({
                contractId: contract.id,
                isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
              })
            }
            data-cy="sp-card-file-claim-button"
          />
          <Button
            emphasis="medium"
            icon={More}
            onClick={toggle}
            ref={triggerRef}
            isToggled={isPresent}
            data-cy="sp-card-more-button"
          />
          <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
            <MenuLinkWrapper>
              <MenuButtonItem onClick={handleReviewTerms} data-cy="sp-card-review-terms-button">
                Review Terms & Conditions
              </MenuButtonItem>
              <MenuButtonItem
                onClick={handleContactSupport}
                data-cy="sp-card-contact-support-button"
              >
                Contact Support
              </MenuButtonItem>
            </MenuLinkWrapper>
          </Popover>
        </HeaderButtons>
      </Header>
      <Body>
        {trackingInfo?.length ? (
          trackingInfo.map((shipment, index) => {
            const shipmentProducts =
              hasManyProducts && !isViewAllExpanded
                ? productsToShow <= 0
                  ? []
                  : shipment.productsShipped.slice(0, productsToShow)
                : shipment.productsShipped
            return (
              !!shipmentProducts?.length && (
                <ShipmentWrapper data-cy="shipment-wrapper">
                  <ShipmentDetails>
                    <ShipmentDetailsTextWrapper data-cy="sp-card-shipment-details">
                      <ShipmentDetailsPretext>{`Ship ${index + 1} · `}</ShipmentDetailsPretext>
                      <ShipmentDetailsText>
                        {shipment.actualDeliveryDate
                          ? `Delivered ${formatDate(shipment.actualDeliveryDate)}`
                          : shipment.estimatedDeliveryDate
                          ? `Arriving ${formatDate(shipment.estimatedDeliveryDate)}`
                          : 'Pending shipment details'}
                      </ShipmentDetailsText>
                      {hasTrackingUrl && (
                        <Button
                          color="neutral"
                          emphasis="low"
                          icon={OpenInNew}
                          size="small"
                          data-cy="tracking-url-link"
                          onClick={() =>
                            window.open(
                              `${
                                contract.trackingInfo[0].trackingUrl ||
                                contract.trackingInfo[0].requestTrackingUrl
                              }`,
                              '_blank',
                            )
                          }
                          tooltip="Track on carrier website"
                        />
                      )}
                    </ShipmentDetailsTextWrapper>
                  </ShipmentDetails>
                  <ProductList>
                    {shipmentProducts.map((productId) => {
                      // eslint-disable-next-line no-plusplus
                      productsToShow--
                      const product = contract.productsList.find((p) => p.referenceId === productId)
                      return (
                        <Product>
                          <ProductImage
                            data-cy={`sp-card-product-image-${productId}`}
                            src={
                              product?.imageUrl ? product?.imageUrl : images.defaultCarouselImage
                            }
                            onError={(e) => handleImageError(e)}
                          />
                          <ProductTitle data-cy="sp-card-product-title">
                            {product?.title}
                          </ProductTitle>
                        </Product>
                      )
                    })}
                  </ProductList>
                </ShipmentWrapper>
              )
            )
          })
        ) : (
          <>
            <ShipmentDetailsText data-cy="sp-card-details-text">
              Pending shipment details
            </ShipmentDetailsText>
            <ProductList>
              {(hasManyProducts && !isViewAllExpanded
                ? contract.productsList.slice(0, 3)
                : contract.productsList
              ).map((product) => {
                return (
                  <Product>
                    <ProductImage
                      data-cy="sp-card-product-image"
                      src={product?.imageUrl ? product?.imageUrl : images.defaultCarouselImage}
                    />
                    <ProductTitle data-cy="sp-card-product-title">{product?.title}</ProductTitle>
                  </Product>
                )
              })}
            </ProductList>
          </>
        )}
        {hasManyProducts && (
          <Button
            emphasis="low"
            color="neutral"
            text={isViewAllExpanded ? 'View less items' : 'View all items'}
            icon={isViewAllExpanded ? ArrowDropDownClose : ArrowDropDown}
            onClick={() => setIsViewAllExpanded(!isViewAllExpanded)}
            data-cy="sp-card-view-more-button"
          />
        )}
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  border: '1px solid',
  borderColor: COLOR.NEUTRAL['300'],
  width: 'fill-available',
  [bp.desktop]: {
    minWidth: '764px',
  },
})

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '16px',
  borderBottom: '1px solid',
  borderColor: COLOR.NEUTRAL['300'],
  [bp.mobile]: {
    flexDirection: 'column',
    padding: '16px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    padding: '24px',
  },
})

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  [bp.mobile]: {
    padding: '16px',
  },
  [bp.desktop]: {
    padding: '24px',
  },
})

const HeaderInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

const HeaderButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  minWidth: '207px',
  [bp.mobile]: { alignSelf: 'flex-start' },
  [bp.desktop]: { alignSelf: 'center' },
})

const BadgeWrapper = styled.div({
  display: 'flex',
  alignSelf: 'flex-start',
  gap: '8px',
  [bp.mobile]: {
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
  [bp.desktop]: { flexDirection: 'row', alignItems: 'normal' },
})

const ClaimInProgressWrapper = styled.div({
  alignSelf: 'flex-start',
})

const MenuLinkWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '8px',
})

const HeaderTitle = styled.div({
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
})

const HeaderDetails = styled.div({
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: '15px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
})

const ShipmentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const ShipmentDetails = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
})

const ShipmentDetailsTextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  lineHeight: '28px',
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
  },
  [bp.desktop]: {
    fontSize: '18px',
  },
  alignItems: 'center',
})
const ShipmentDetailsText = styled.div({
  fontWeight: 700,
  [bp.mobile]: {
    fontSize: '17px',
    lineHeight: '33px',
  },
  [bp.desktop]: {
    fontSize: '18px',
    lineHeight: '34px',
  },
})
const ShipmentDetailsPretext = styled.div({
  color: COLOR.NEUTRAL['500'],
})

const ProductList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const Product = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
})

const ProductImage = styled.img({
  [bp.mobile]: {
    width: '40px',
    height: '40px',
    borderRadius: '4px',
  },
  [bp.desktop]: {
    width: '56px',
    height: '56px',
    borderRadius: '8px',
  },
})

const ProductTitle = styled.div({
  fontWeight: 400,
  [bp.mobile]: {
    fontSize: '15px',
    lineHeight: '20px',
  },
  [bp.desktop]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
})

export { ShippingProtectionCard }
