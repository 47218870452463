import { createApi } from '@reduxjs/toolkit/query/react'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { clientTypes as ContractsClientTypes } from '@extend-contracts/client'
import type { PrecheckRequest, PrecheckResponse } from './types'
import { baseQuery } from '../base-query'

type ContractsSearchResponse = ContractsClientTypes.ContractsSearchResponseVersionsMap['2023-07-01']
type ContractsSearchIndividual = ContractsSearchResponse['contracts'][0]

export const adjudicationManagementApi = createApi({
  baseQuery,
  reducerPath: 'adjudication-management',
  tagTypes: ['adjudication-management'],
  endpoints: (build) => ({
    precheck: build.query<PrecheckResponse, PrecheckRequest>({
      query: (body) => {
        return {
          url: `adjudication-management/pre-check`,
          method: 'POST',
          body,
        }
      },
    }),
    precheckAll: build.query<Record<string, PrecheckResponse>, ContractsSearchIndividual[]>({
      async queryFn(contracts, _queryApi, _extraOptions, fetchWithBQ) {
        const promises = contracts.map(async (contract) => {
          const res: QueryReturnValue = await fetchWithBQ({
            url: `adjudication-management/pre-check`,
            method: 'POST',
            body: {
              contractId: contract.id,
            },
          })

          if (res.error) {
            // eslint-disable-next-line no-console
            console.error(res.error)
          }
          const payload = res.data as PrecheckResponse
          return { id: contract.id, payload }
        })

        const results = await Promise.all(promises)

        const data: Record<string, PrecheckResponse> = {}
        results.forEach(({ id, payload }) => {
          data[id] = payload
        })

        return { data }
      },
    }),
  }),
})

export const { usePrecheckQuery, usePrecheckAllQuery } = adjudicationManagementApi
