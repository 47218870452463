import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Grid, Stack } from '@extend/zen'
import { bp } from '@helloextend/customers-ui'
import { ShippingProtectionCharityContent, images } from '../../../../lib/assets'
import { isMobile } from 'react-device-detect'

interface CharityProps {
  charityPlanDetailsHeadline?: string
  charityPlanDetailsSubheadline?: string
  charityPlanDetailsImage?: string
}

const Charity: FC<CharityProps> = ({
  charityPlanDetailsHeadline,
  charityPlanDetailsSubheadline,
  charityPlanDetailsImage,
}) => {
  const heading =
    charityPlanDetailsHeadline ?? ShippingProtectionCharityContent.charityPlanDetailsHeadline
  const subHeadline =
    charityPlanDetailsSubheadline ?? ShippingProtectionCharityContent.charityPlanDetailsSubheadline

  return (
    <div style={{ marginTop: 50, width: '100%' }}>
      {isMobile ? (
        <>
          <Stack spacingY={1}>
            <Heading>{heading}</Heading>
            <CharityImage imageUrl={charityPlanDetailsImage ?? images.charity} />
            <ContentText>{subHeadline}</ContentText>
          </Stack>
        </>
      ) : (
        <Grid columns={2}>
          <Stack isRow={true} justify="center">
            <CharityImage imageUrl={charityPlanDetailsImage ?? images.charity} />
          </Stack>
          <Stack justify="center" align="center" spacingY={1}>
            <Heading>{heading}</Heading>
            <ContentText>{subHeadline}</ContentText>
          </Stack>
        </Grid>
      )}
    </div>
  )
}

const CharityImage: FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return <img style={{ borderRadius: 20 }} src={imageUrl} alt="Charitable Donation" />
}

const Heading = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
  },
  [bp.desktop]: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '48px',
  },
  textAlign: 'center',
  width: '100%',
  fontWeight: 600,
})

const ContentText = styled.div({
  fontSize: 20,
  lineHeight: '30px',
  fontWeight: 400,
  color: COLOR.NEUTRAL[800],
  marginTop: '4%',
  [bp.mobile]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 400,
  },
  textAlign: 'center',
})

export { Charity }
