import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { bp, Text } from '@helloextend/customers-ui'

interface LearnMoreProps {
  learnMoreHeader?: string
  learnMoreBody?: string
  learnMoreButtonText?: string
}

const LearnMore: FC<LearnMoreProps> = ({ learnMoreHeader, learnMoreBody, learnMoreButtonText }) => {
  const handleClick = (): void => {
    window.open('https://www.extend.com/shipping-protection-guide')
  }

  return (
    <LearnMoreWrapper>
      <LearnMoreDetailWraper>
        <LearnMoreHeader>
          <Text>{learnMoreHeader || 'Want to learn more?'}</Text>
        </LearnMoreHeader>
        <LearnMoreSubHeader>
          <Text>{learnMoreBody || "Read Extend's Guide to Shipping Protection."}</Text>
        </LearnMoreSubHeader>
      </LearnMoreDetailWraper>
      <ShopperGuideButton onClick={handleClick}>
        {learnMoreButtonText || 'Read Now'}
      </ShopperGuideButton>
    </LearnMoreWrapper>
  )
}
const LearnMoreWrapper = styled.div({
  background: COLOR.BLUE[1000],
  borderRadius: 24,
  padding: 20,
  width: '100%',
  marginTop: '9%',
  marginBottom: '8%',
  textAlign: 'center',
  zIndex: 1,
  [bp.mobile]: {
    marginTop: '26%',
  },
  [bp.desktopMd]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '48px 80px',
    alignItems: 'center',
    maxWidth: '100%',
    marginTop: '8%',
  },
})

const LearnMoreDetailWraper = styled.div({
  marginBottom: 28,
  textAlign: 'left',
  [bp.desktopMd]: {
    marginBottom: 0,
  },
})

const LearnMoreHeader = styled.div({
  fontWeight: 700,
  color: COLOR.WHITE,
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
  },
  [bp.desktopMd]: {
    fontSize: 40,
    lineHeight: '54px',
  },
  textAlign: 'center',
})
const LearnMoreSubHeader = styled.div({
  color: COLOR.WHITE,
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '24px',
    marginTop: '5px',
  },
  [bp.desktopMd]: {
    fontSize: 20,
    lineHeight: '28px',
  },
  textAlign: 'center',
})

const ShopperGuideButton = styled.button({
  backgroundColor: COLOR.WHITE,
  color: COLOR.BLUE[1000],
  border: `1px solid ${COLOR.NEUTRAL[100]}`,
  borderRadius: 6,
  cursor: 'pointer',
  fontSize: 20,
  fontWeight: 700,
  padding: '10px 24px',
  lineHeight: '28px',
  '&:focus': {
    backgroundColor: COLOR.NEUTRAL[200],
    borderColor: COLOR.NEUTRAL[200],
  },
  '&:hover,&:active': {
    borderColor: COLOR.NEUTRAL[200],
    backgroundColor: COLOR.NEUTRAL[200],
  },
})

export { LearnMore }
