import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { Link } from 'react-router-dom'
import { bp, Text } from '@helloextend/customers-ui'
import { images } from '../../../../lib/assets'

interface HowShippingProtectionWorksProps {
  processExplainedHeader?: string
  processExplainedImage?: string
  processExplainedStep1Body?: string
  processExplainedStep1Header?: string
  processExplainedStep1Label?: string
  processExplainedStep2Body?: string
  processExplainedStep2Header?: string
  processExplainedStep2Label?: string
  processExplainedStep3Body?: string
  processExplainedStep3Header?: string
  processExplainedStep3Label?: string
}

const HowShippingProtectionWorks: FC<HowShippingProtectionWorksProps> = ({
  processExplainedHeader,
  processExplainedImage,
  processExplainedStep1Body,
  processExplainedStep1Header,
  processExplainedStep1Label,
  processExplainedStep2Body,
  processExplainedStep2Header,
  processExplainedStep2Label,
  processExplainedStep3Body,
  processExplainedStep3Header,
  processExplainedStep3Label,
}) => {
  return (
    <HowShippingProtectionWorksWrapper>
      <HowTitle>
        <Text>{processExplainedHeader || 'How It Works'}</Text>
      </HowTitle>
      <BodyWrapper>
        <ImageWrapper>
          {processExplainedImage && <ImagePanel />}
          <LeftPanelImg src={processExplainedImage || images.spWoman} alt="SP Woman" />
        </ImageWrapper>
        <RightPanelWrapper>
          <CardWrapper>
            <CardContent>
              <CardNumber>
                <Text>{processExplainedStep1Label?.toUpperCase() || 'STEP ONE'}</Text>
              </CardNumber>
              <CardHeader>
                <Text>{processExplainedStep1Header || 'Purchase Protection.'}</Text>
              </CardHeader>
              <CardBody>
                <Text>
                  {processExplainedStep1Body ||
                    `Make sure Shipping Protection by Extend is added to your purchase at checkout. You’ll receive an email confirmation with details and then you can sit back and relax!`}
                </Text>
              </CardBody>
            </CardContent>
          </CardWrapper>
          <CardWrapper>
            <CardContent>
              <CardNumber>
                <Text>{processExplainedStep2Label?.toUpperCase() || 'STEP TWO'}</Text>
              </CardNumber>
              <CardHeader>
                <Text>{processExplainedStep2Header || 'File a claim.'}</Text>
              </CardHeader>
              <CardBody>
                <Text>
                  {processExplainedStep2Body || (
                    <>
                      If your package is lost, stolen, or damaged, don’t worry. Visit{' '}
                      <Link to="/my_plans">customers.extend.com</Link> and file a claim with your email
                      address or contract ID. No need to call your shipping carrier or the retailer!
                    </>
                  )}
                </Text>
              </CardBody>
            </CardContent>
          </CardWrapper>
          <CardWrapper>
            <CardContent>
              <CardNumber>
                <Text>{processExplainedStep3Label?.toUpperCase() || 'STEP THREE'}</Text>
              </CardNumber>
              <CardHeader>
                <Text>{processExplainedStep3Header || 'Enjoy your replacement.'}</Text>
              </CardHeader>
              <CardBody>
                <Text>
                  {processExplainedStep3Body ||
                    `Most often Extend will send you a virtual gift card to order a replacement product. In some cases, Extend will ship the replacement directly to you.`}
                </Text>
              </CardBody>
            </CardContent>
          </CardWrapper>
        </RightPanelWrapper>
      </BodyWrapper>
    </HowShippingProtectionWorksWrapper>
  )
}

const HowShippingProtectionWorksWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 123,
  [bp.mobile]: {
    marginTop: '69px',
  },
})

const HowTitle = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
  },
  [bp.desktop]: {
    fontSize: 40,
    lineHeight: '48px',
  },
  fontWeight: 700,
})

const BodyWrapper = styled.div({
  display: 'flex',
  [bp.mobile]: {
    marginTop: 50,
    flexDirection: 'column-reverse',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
  justifyContent: 'space-evenly',
  width: '100%',
})

const RightPanelWrapper = styled.div({
  [bp.desktop]: {
    marginLeft: 40,
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
})

const CardWrapper = styled.div({
  [bp.mobile]: {
    marginBottom: 50,
  },
  [bp.desktop]: {
    marginBottom: 30,
  },
  display: 'flex',
})

const CardNumber = styled.div({
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '36px',
    fontWeight: 700,
  },
  [bp.desktop]: {
    fontSize: 16,
  },
  fontWeight: 600,
  color: COLOR.BLUE[800],
})

const CardContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  marginTop: 10,
  [bp.mobile]: {
    alignItems: 'center',
  },
})

const CardHeader = styled.div({
  [bp.mobile]: {
    fontSize: 20,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '32px',
  },
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[1000],
})

const CardBody = styled.div({
  [bp.mobile]: {
    fontSize: 15,
    lineHeight: '20px',
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 400,
    width: '80%',
  },
  color: COLOR.NEUTRAL[700],
  marginTop: 10,
})

const ImageWrapper = styled.div({
  position: 'relative',
  margin: '0 auto',
  [bp.mobile]: {
    width: 345,
    height: 344,
  },
  [bp.desktop]: {
    minWidth: 526,
    height: 523,
  },
})

const ImagePanel = styled.div({
  backgroundColor: COLOR.NEUTRAL[100],
  borderRadius: 10.5,
  position: 'absolute',
  left: 0,
  bottom: 0,
  [bp.mobile]: {
    width: 312,
    height: 312,
  },
  [bp.desktop]: {
    width: 477,
    height: 477,
  },
})

const LeftPanelImg = styled.img({
  position: 'relative',
  width: 'auto',
  height: '100%',
})

export { HowShippingProtectionWorks }
