import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ContractsSearchIndividual, ContractsSearchResponse } from '../../types/contract'
import { sortContracts } from '../../lib/helper-functions'
import { ContractList } from './contract-list'

type ContractListContainerProps = ContractsSearchResponse

const ContractListContainer: FC<ContractListContainerProps> = ({ contracts }) => {
  const sortedContracts = sortContracts([...contracts])

  return (
    <ContractListWrapper data-cy="contracts-list-wrapper">
      {!!contracts.length && (
        <ContractList
          contracts={sortedContracts as unknown as ContractsSearchIndividual[]}
          data-cy="contract-list-active"
        />
      )}
    </ContractListWrapper>
  )
}

const ContractListWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})

export { ContractListContainer }
