import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { ModalController } from '@extend/zen'
import type { ContractsSearchResponse, ContractsSearchIndividual } from '../../types/contract'
import { logEvent } from '../../analytics'
import { FileClaimLanguageOptionModal } from '../../components/file-claim-language-option-modal/file-claim-language-option-modal'
import CardMapper from './card-mapper'
import ContactMerchantModal from '../../components/contact-merchant-modal'

export type ContractListProps = ContractsSearchResponse

const ContractList: FC<ContractListProps> = ({ contracts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false)
  const [isFilingClaim, setIsFilingClaim] = useState(false)
  const [contractAndLineItem, setContractAndLineItem] = useState<{
    contractId: string
    lineItemId?: string
  }>()

  const history = useHistory()
  const { locale } = useIntl()

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  const handleFileClaim = useCallback((): void => {
    if (!contractAndLineItem?.contractId) return

    logEvent('My Plans - Contract Card - Clicks', 'File a Claim')
    history.push(`/${locale}/kaley`, {
      contractId: contractAndLineItem?.contractId,
      ...(contractAndLineItem?.lineItemId && {
        lineItemIds: [contractAndLineItem?.lineItemId],
      }),
    })
  }, [contractAndLineItem, history, locale])

  const handleFileClaimClick = (handlerArgs: {
    contractId: string
    lineItemId?: string
    isMerchantOwnedIntake?: boolean
  }): void => {
    setContractAndLineItem({
      contractId: handlerArgs.contractId,
      lineItemId: handlerArgs.lineItemId,
    })
    if (handlerArgs.isMerchantOwnedIntake) {
      setIsCustomerSupportModalOpen(true)
    } else if (['fr-CA', 'en-CA'].includes(locale)) {
      handleModalOpen()
    } else {
      setIsFilingClaim(true)
    }
  }

  useEffect(() => {
    if (isFilingClaim && contractAndLineItem?.contractId) {
      handleFileClaim()
      setIsFilingClaim(false)
    }
  }, [isFilingClaim, contractAndLineItem, handleFileClaim])

  const cards = contracts.map((contract: ContractsSearchIndividual) => {
    return (
      <CardMapper
        key={contract.id}
        contract={contract}
        handleFileClaimClick={handleFileClaimClick}
      />
    )
  })
  const selectedContract = contracts.find((c) => c.id === contractAndLineItem?.contractId)

  return (
    <ContractsWrapper data-cy="contract-list">
      {cards}
      <FileClaimLanguageOptionModal
        isModalOpen={isModalOpen}
        onDismiss={handleModalClose}
        onContinue={handleFileClaim}
      />
      {selectedContract && (
        <ModalController isOpen={isCustomerSupportModalOpen}>
          <ContactMerchantModal
            onDismissRequest={() => setIsCustomerSupportModalOpen(false)}
            contract={selectedContract}
          />
        </ModalController>
      )}
    </ContractsWrapper>
  )
}

const ContractsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  [bp.mobile]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 0,
    gap: '24px',
  },
})

export { ContractList }
