import React from 'react'
import type { FC } from 'react'
import type {
  Claim as ApiClientClaim,
  ClaimPhotosGetResponse,
  ServiceOrder,
} from '@helloextend/extend-api-client'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import {
  ClaimPreparingState,
  ClaimDenied,
  ClaimPendingExtendOwnedLabelTimeline,
  CustomerPayoutTimeline,
  ClaimManualReviewTimeline,
  ClaimClosedState,
  FulfilledTimeline,
  RepairAcceptedServicerOwnedTimeline,
  ManualReplacementApprovedTimeline,
  RepairInProgressTimeline,
  ClaimPendingAdjudication,
} from './timelines'
import type { ContractsSearchIndividual } from '../../types/contract'
import type { Claim } from '../../types/claim'
import { hasUnmetPhotoRequirements } from '../../lib/helper-functions'
import { ClaimPendingPhotoRequirements } from './timelines/claim-pending-photo-requirements'

export interface ClaimStateMachineProps {
  claim: ApiClientClaim
  serviceOrders?: ServiceOrder[]
  merchantServicingSettings?: MerchantServicingSettings
  contract?: ContractsSearchIndividual
  photoData?: ClaimPhotosGetResponse
  handleResultModalOpen: () => void
}

export const ClaimStateMachine: FC<ClaimStateMachineProps> = ({
  claim,
  serviceOrders,
  merchantServicingSettings,
  contract,
  photoData,
  handleResultModalOpen,
}) => {
  const getActiveServiceOrder = (): ServiceOrder | undefined => {
    if (!serviceOrders?.length) return undefined
    return [...serviceOrders].sort((a, b) => b.updatedAt - a.updatedAt)[0]
  }
  // Claim pending_adjudication, denied, review, and closed don't need SO render before trying to load
  // service orders or merchant servicing settings
  if (claim.status === 'pending_adjudication') {
    if (hasUnmetPhotoRequirements(claim as Claim, photoData) && !!claim?.customer.shippingAddress) {
      return (
        <ClaimPendingPhotoRequirements
          claim={claim as Claim}
          data-cy="claim-pending-photo-requirements"
          photoData={photoData}
          handleResultModalOpen={handleResultModalOpen}
        />
      )
    }

    return (
      <ClaimPendingAdjudication data-cy="claim-pending-no-requirements" claim={claim as Claim} />
    )
  }

  if (claim.status === 'denied') {
    return <ClaimDenied data-cy="claim-denied" claim={claim} />
  }
  if (claim.status === 'review') {
    return <ClaimManualReviewTimeline />
  }
  if (claim.status === 'closed') {
    return <ClaimClosedState claim={claim} data-cy="claim-closed" />
  }

  const activeServiceOrder = getActiveServiceOrder()

  if (
    claim.serviceType === 'repair' &&
    claim.status === 'approved' &&
    (!activeServiceOrder || activeServiceOrder.status === 'created')
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  // Display preparing state if service order or
  // merchant servicing settings are not yet loaded
  // Previous claim statuses above did not require SO or merchant servicing settings
  if (!activeServiceOrder || !merchantServicingSettings) {
    return <ClaimPreparingState />
  }

  const fulfilledServiceOrderStatuses = [
    'fulfilled',
    'payment_requested',
    'payment_approved',
    'paid',
  ]

  if (
    claim.status === 'fulfilled' ||
    fulfilledServiceOrderStatuses.includes(activeServiceOrder.status) ||
    activeServiceOrder.configurations?.replacementFulfillmentMethod === 'virtual_card' ||
    activeServiceOrder.configurations?.replacementFulfillmentMethod === 'direct_payment'
  ) {
    return <FulfilledTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    activeServiceOrder.configurations?.hasCustomDefectiveLabel &&
    activeServiceOrder.serviceType === 'repair_depot' &&
    activeServiceOrder.status === 'accepted'
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    activeServiceOrder.configurations?.repairFulfillmentMethod &&
    activeServiceOrder.serviceType === 'repair' &&
    (activeServiceOrder.status === 'assigned' || activeServiceOrder.status === 'accepted')
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    claim.status === 'approved' &&
    activeServiceOrder.serviceType === 'repair_onsite' &&
    activeServiceOrder.status === 'accepted'
  ) {
    return <RepairAcceptedServicerOwnedTimeline claim={claim} serviceOrder={activeServiceOrder} />
  }

  if (
    activeServiceOrder.status === 'accepted' &&
    activeServiceOrder.configurations?.productReturnRequired &&
    !activeServiceOrder.configurations?.hasCustomDefectiveLabel
  ) {
    return (
      <ClaimPendingExtendOwnedLabelTimeline
        data-cy="pending-claim-extend-owned-label"
        serviceOrder={activeServiceOrder}
        merchantServicingSettings={merchantServicingSettings}
      />
    )
  }

  if (
    claim.status === 'approved' &&
    activeServiceOrder.status === 'created' &&
    activeServiceOrder.serviceType === 'replace'
  ) {
    return (
      <CustomerPayoutTimeline
        claim={claim}
        merchantServicingSettings={merchantServicingSettings}
        serviceOrder={activeServiceOrder}
        contract={contract}
      />
    )
  }
  if (
    claim.status === 'approved' &&
    activeServiceOrder.status === 'replacement_approved' &&
    activeServiceOrder.configurations?.replacementFulfillmentMethod === 'manual'
  ) {
    return <ManualReplacementApprovedTimeline />
  }

  if (
    claim.status === 'approved' &&
    activeServiceOrder.serviceType === 'repair_depot' &&
    (activeServiceOrder.status === 'repair_started' ||
      activeServiceOrder.status === 'repair_shipped')
  ) {
    return <RepairInProgressTimeline serviceOrder={activeServiceOrder} />
  }
  return <ClaimPreparingState />
}
