import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'
import { bp, Text } from '@helloextend/customers-ui'
import { images } from '../../../../lib/assets'

interface WhyExtendProps {
  valuePropositionBody?: string
  valuePropositionHeader?: string
  valuePropositionImage?: string
}

const WhyExtend: FC<WhyExtendProps> = ({
  valuePropositionHeader,
  valuePropositionBody,
  valuePropositionImage,
}) => {
  return (
    <WhyExtendWrapper>
      <LeftPanelWrapper>
        <LeftPanelBodyWrapper>
          <LeftPanelTitle>
            <Text>{valuePropositionHeader || 'Why Add Extend?'}</Text>
          </LeftPanelTitle>
          <PanelContentText>
            <Text>
              {valuePropositionBody ||
                `Extend provides affordable shipping protection with a modern, easy-to understand process. Extend Shipping Protection protects your package en route; whether it’s a mixup on the truck, a mishandling from a shipping carrier, or it's stolen while you’re at work, you’re covered`}
            </Text>
          </PanelContentText>
        </LeftPanelBodyWrapper>
      </LeftPanelWrapper>
      <RightPanelWrapper>
        <WhyKaleyNoMobileImg
          src={valuePropositionImage || images.whyKaleyNoMobile}
          alt="Why Extend"
        />
      </RightPanelWrapper>
    </WhyExtendWrapper>
  )
}

const WhyKaleyNoMobileImg = styled.img({
  marginTop: 20,
})

const WhyExtendWrapper = styled.div({
  display: 'flex',
  marginTop: 80,
  width: '100%',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const LeftPanelWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [bp.mobile]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: '75%',
  },
})

const RightPanelWrapper = styled.div({
  [bp.mobile]: {
    marginTop: 0,
  },
  [bp.desktop]: {
    marginTop: 0,
    marginLeft: '4%',
  },
  display: 'flex',
})

const LeftPanelTitle = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
  },
  [bp.desktop]: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '48px',
  },

  width: '100%',
  fontWeight: 600,
})

const PanelContentText = styled.div({
  fontSize: 20,
  lineHeight: '30px',
  fontWeight: 400,
  color: COLOR.NEUTRAL[800],
  marginTop: '4%',
  [bp.mobile]: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 400,
  },
})

const LeftPanelBodyWrapper = styled.div({
  [bp.desktop]: {
    height: '75%',
    marginTop: '11%',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

export { WhyExtend }
