import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp, Card, Text } from '@helloextend/customers-ui'
import { v4 as uuid } from 'uuid'
import { COLOR } from '@extend/zen'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { useScreenSize } from '@helloextend/client-hooks'
import { svgs } from '../../../../lib/assets'

interface ShippingProtectionCoverProps {
  coverageHeader?: string
  coverageItem1Icon?: string
  coverageItem1Title?: string
  coverageItem1Description?: string
  coverageItem2Icon?: string
  coverageItem2Title?: string
  coverageItem2Description?: string
  coverageItem3Icon?: string
  coverageItem3Title?: string
  coverageItem3Description?: string
}

const ShippingProtectionCover: FC<ShippingProtectionCoverProps> = ({
  coverageHeader,
  coverageItem1Icon,
  coverageItem1Title,
  coverageItem1Description,
  coverageItem2Icon,
  coverageItem2Title,
  coverageItem2Description,
  coverageItem3Icon,
  coverageItem3Title,
  coverageItem3Description,
}) => {
  const screenSize = useScreenSize()
  const isSmallScreen = screenSize === 'small'
  const visibleSlidesCount = (): number => {
    if (screenSize === 'small') return 1
    if (screenSize === 'medium') return 2
    if (screenSize === 'large') return 2
    return 3
  }
  const worryFreeBubble = (
    <ContentDetailWrapper>
      <ContentImgWrapper>
        {(coverageItem1Icon && <img src={coverageItem1Icon} alt="package protection" />) || (
          <svgs.WorryFreePackage />
        )}
      </ContentImgWrapper>
      <ContentDetailTextWrapper>
        <ContentDetailTitle>
          <Text>{coverageItem1Title || 'Worry-free shipping protection'}</Text>
        </ContentDetailTitle>
        <ContentDetailText>
          <Text>
            {coverageItem1Description ||
              'You’re covered with a replacement in case of loss, damage, or theft.'}
          </Text>
        </ContentDetailText>
      </ContentDetailTextWrapper>
    </ContentDetailWrapper>
  )
  const hassleFreeBubble = (
    <ContentDetailWrapper>
      <ContentImgWrapper>
        <ClaimTextBubbles>
          {(coverageItem2Icon && <img src={coverageItem2Icon} alt="two chat bubbles" />) || (
            <svgs.ClaimTextBubbles />
          )}
        </ClaimTextBubbles>
      </ContentImgWrapper>
      <ContentDetailTextWrapper>
        <ContentDetailTitle>
          <Text>{coverageItem2Title || 'Hassle-free claims'}</Text>
        </ContentDetailTitle>
        <ContentDetailText>
          <Text>
            {coverageItem2Description ||
              'No deductibles or fees. Simply file a claim with Kaley, our virtual claims assistant.'}
          </Text>
        </ContentDetailText>
      </ContentDetailTextWrapper>
    </ContentDetailWrapper>
  )
  const convenientSupportBubble = (
    <ContentDetailWrapper>
      <ContentImgWrapper>
        <ConvenientSupportHeart>
          {(coverageItem3Icon && <img src={coverageItem3Icon} alt="heart" />) || (
            <svgs.ConvenientSupportHeart />
          )}
        </ConvenientSupportHeart>
      </ContentImgWrapper>
      <ContentDetailTextWrapper>
        <ContentDetailTitle>
          <Text>{coverageItem3Title || 'Convenient support'}</Text>
        </ContentDetailTitle>
        <ContentDetailText>
          <Text>
            {coverageItem3Description ||
              'Extend is always happy to help and has online support 24/7.'}
          </Text>
        </ContentDetailText>
      </ContentDetailTextWrapper>
    </ContentDetailWrapper>
  )

  const bubbles = [worryFreeBubble, hassleFreeBubble, convenientSupportBubble]

  return (
    <ShippingProtectionCoverWrapper>
      <br />
      <br />
      <ContentInfoTitle>
        <Text>{coverageHeader || 'Coverage & Benefits'}</Text>
      </ContentInfoTitle>
      <CarouselProviderWrapper
        visibleSlides={visibleSlidesCount()}
        totalSlides={3}
        step={visibleSlidesCount()}
        naturalSlideWidth={301}
        naturalSlideHeight={300}
        touchEnabled={isSmallScreen}
        dragEnabled={isSmallScreen}
        isSmallScreen={isSmallScreen}
        bubbleheight={300}
      >
        <ContentInfoWrapper>
          <SliderContainer height={400} isSmallScreen={isSmallScreen}>
            <SliderWrapper>
              {bubbles.map((entry, i) => {
                return (
                  <Slide index={i} key={uuid()}>
                    {entry}
                  </Slide>
                )
              })}
            </SliderWrapper>
          </SliderContainer>
        </ContentInfoWrapper>
      </CarouselProviderWrapper>
      <br />
      <br />
    </ShippingProtectionCoverWrapper>
  )
}

const ShippingProtectionCoverWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  backgroundColor: COLOR.BLUE[1000],
  [bp.mobile]: {
    width: 'calc(100% + 40px)',
    marginTop: '420px',
  },
  [bp.desktop]: {
    width: 'calc(100% + 240px)',
    marginTop: '0px',
  },
})

const CarouselProviderWrapper = styled(CarouselProvider)<{
  bubbleheight: number
  isSmallScreen: boolean
}>(
  {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    [bp.mobile]: {
      paddingLeft: '5%',
    },
    [bp.desktop]: {
      marginLeft: '7%',
    },
  },
  ({ bubbleheight, isSmallScreen }) => ({
    height: isSmallScreen ? 330 : 436,
    '& button': {
      transform: `translateY(-${bubbleheight / 3}px)`,
      [bp.desktop]: {
        transform: `translateY(-${bubbleheight / 2}px)`,
      },
      [bp.desktopMd]: {
        transform: `translateY(-${bubbleheight / 3}px)`,
      },
    },
  }),
)

interface SliderContainerProps {
  isSmallScreen: boolean
  height: number
}

const SliderWrapper = styled(Slider)({
  overflowX: 'hidden',
  height: '100%',
  '*': {
    outlineStyle: 'none !important',
  },
  [bp.mobile]: {
    width: '100%',
    padding: '0px 20px',
  },
  [bp.tablet]: {
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
  },
})
const SliderContainer = styled.div<SliderContainerProps>(
  {
    flex: 1,
    margin: '0px',
    width: '100%',
    [bp.tablet]: {
      width: '100%',
    },
  },
  (props) => ({
    height: props.height,
  }),
)
const ContentInfoTitle = styled.div({
  fontWeight: 700,
  color: COLOR.WHITE,
  [bp.mobile]: {
    fontSize: 24,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    fontSize: 40,
    lineHeight: '48px',
  },
})

const ClaimTextBubbles = styled.div({
  marginTop: '-16px',
})

const ConvenientSupportHeart = styled.div({
  marginTop: '15px',
})

const ContentInfoWrapper = styled.div({
  marginTop: 40,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: 1216,
  [bp.mobile]: {
    justifyContent: 'space-between',
    height: 800,
    flexDirection: 'column',
  },
  [bp.desktop]: {
    gap: 0,
    height: 'auto',
    flexDirection: 'row',
  },
})

const ContentImgWrapper = styled.div({
  [bp.mobile]: {
    marginTop: 0,
    '& svg': {
      height: 57,
      width: 65,
    },
  },
  [bp.desktop]: {
    marginTop: 10,
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
})

const ContentDetailWrapper = styled(Card)({
  [bp.mobile]: {
    height: 282,
    width: 287,
    padding: '32px 0',
  },
  [bp.desktop]: {
    height: 378,
    width: '90%',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 24,
  backgroundColor: COLOR.WHITE,
})

const ContentDetailTitle = styled.div({
  fontWeight: 700,
  textAlign: 'center',
  [bp.mobile]: {
    fontSize: 20,
    lineHeignt: '24px',
  },
  [bp.desktop]: {
    fontSize: 24,
    lineHeignt: '32px',
  },
})

const ContentDetailTextWrapper = styled.div({
  [bp.mobile]: {
    marginTop: 16,
  },
  [bp.desktop]: {
    marginTop: 35,
    padding: '2px',
  },
})

const ContentDetailText = styled.div({
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
  },
  textAlign: 'center',
  maxWidth: '90%',
  margin: '10px auto',
  color: COLOR.NEUTRAL[800],
})

export { ShippingProtectionCover }
