import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { post } from '@helloextend/extend-api-fetch'
import { customLogger } from '@extend/client-helpers'
import { getTokenSub } from '@helloextend/client-security-utils'
import type { ConnectRequest, ConnectResponse } from '@extend-incredibot/types'
import type {
  Reply,
  Slot,
  SlotValue,
  ContactFormValues,
} from '../models/claims-assistant'

export async function connect({
  source,
  filedBy,
  contractId,
  orderId,
  lineItemIds,
}: ConnectRequest): Promise<ApiResponse<ConnectResponse>> {
  return post('/claims-assistant/connect', {
    retry: true,
    body: {
      source,
      filedBy,
      contractId,
      orderId,
      lineItemIds,
      apiVersion: 'latest',
    },
  })
}

interface UpdateOpts {
  slotValue?: SlotValue
  message?: string
}

export async function update(
  slot: Slot,
  accessToken: string,
  opts: UpdateOpts = {},
): Promise<ApiResponse<Reply>> {
  const sessionId = getTokenSub(accessToken)

  const { slotValue, message } = opts

  customLogger.info(`Calling /update, sessionId: ${sessionId}`, {
    sessionId,
    slot,
    slotValue,
    timestamp: Date.now(),
  })

  return post('/claims-assistant/update', {
    retry: true,
    accessToken,
    body: {
      slot,
      slotValue,
      message,
    },
  })
}

export function contact(formValues: ContactFormValues): Promise<ApiResponse> {
  return post('/claims-assistant/contact', {
    body: formValues,
  })
}
