import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { bp, ContentLayout } from '@helloextend/customers-ui'
import { customLogger } from '@extend/client-helpers'
import {
  isRtkFetchBaseQueryError,
  useGetCharityContentQuery,
  useGetShippingProtectionContentQuery,
} from '@helloextend/extend-api-rtk-query'
import { SplashScreen } from '@helloextend/component-commons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as reactIntl from 'react-intl'
import { useLocation, useParams } from 'react-router'
import { querystring } from '@extend/client-helpers'
import type { OfferType } from '@helloextend/extend-api-client'
import { ResourceErrorBoundary } from '../../../../components/resource-error-boundary'
import { ContentHeader } from './content-header'
import { Faqs } from './faq'
import { ContentBody } from './content-body'
import { LDFlag } from '../../../../constants/ld-flags'
import { ShippingProtectionCharityContent } from '../../../../lib/assets'

const ShippingProtectionPlanDetailsPage: FC = () => {
  const { id: planId } = useParams<{ id?: string }>()
  const { search } = useLocation()
  const { offerType, storeId, charityId, extendProductType } = querystring.parse<{
    offerType?: OfferType
    storeId?: string
    charityId?: string
    extendProductType?: string
  }>(search)

  const { locale } = reactIntl.useIntl()
  const { [LDFlag.ShippingProtectionPlanDetailsCCSFetch]: FF_PLAN_DETAILS_FETCH } = useFlags()
  const isCcsPlanDetailsEnabled = FF_PLAN_DETAILS_FETCH?.ccsPlanDetailsEnabled
  const {
    data: offerData,
    isFetching: offerIsFetching,
    error: offerError,
  } = useGetShippingProtectionContentQuery(
    {
      planId,
      offerType,
      locale,
      storeId,
    },
    { skip: !planId || !isCcsPlanDetailsEnabled },
  )
  const {
    data: charityData,
    isFetching: charityIsFetching,
    error: charityError,
  } = useGetCharityContentQuery(
    {
      charityId,
      storeId,
      extendProductType,
      locale,
    },
    { skip: !charityId },
  )

  useEffect(() => {
    const props = { offerType, storeId, charityId, extendProductType, planId }

    if (offerError) {
      customLogger.error(
        '[Err: Shipping Protection Page - offer]: Request Error',
        isRtkFetchBaseQueryError(offerError)
          ? { status: offerError.status, ...props }
          : { message: offerError.message, ...props },
      )
    }
    if (charityError) {
      customLogger.error(
        '[Err: Shipping Protection Page - charity]: Request Error',
        isRtkFetchBaseQueryError(charityError)
          ? { status: charityError.status, ...props }
          : { message: charityError.message, ...props },
      )
    }
  }, [offerError, charityError])

  if (offerIsFetching || charityIsFetching) {
    return (
      <ResourceErrorBoundary>
        <ContentLayout>
          <SplashScreen data-cy="splash-screen" />
        </ContentLayout>
      </ResourceErrorBoundary>
    )
  }

  const offerContentDetails = isCcsPlanDetailsEnabled
    ? offerData?.results?.[0]?.payloads?.shippingProtectionPlanDetails
    : undefined
  const offerFaqDetails = isCcsPlanDetailsEnabled
    ? offerData?.results?.[0]?.payloads?.shippingProtectionPlanDetailsFaqs
    : undefined
  const charityContentDetails = charityId
    ? charityData?.results?.[0]?.payloads.donationConfiguration
    : undefined

  const allContentDetails = {
    shippingProtectionContent: offerContentDetails,
    charityContentEnabled: !!charityId,
    charityContent: charityContentDetails,
  }

  return (
    <StyledContentLayout pageTitle="Shipping Protection Plan Details Page">
      <Wrapper>
        <ContentHeader {...offerContentDetails} />
        <ContentBody {...allContentDetails} />
        <Faqs {...offerFaqDetails} />
        {charityId && (
          <CharityDisclosureText>
            {charityContentDetails?.charityPlanDetailsDisclosureText ||
              ShippingProtectionCharityContent.charityModalDisclosureText}
          </CharityDisclosureText>
        )}
      </Wrapper>
    </StyledContentLayout>
  )
}

const StyledContentLayout = styled(ContentLayout)({
  overflowX: 'hidden',
})

const Wrapper = styled.div({
  width: 'inherit',
  [bp.mobile]: {
    padding: '42px 20px 0',
  },
  [bp.desktop]: {
    padding: '42px 120px 0',
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

const CharityDisclosureText = styled.div({
  textAlign: 'center',
})

export { ShippingProtectionPlanDetailsPage }
