import styled from '@emotion/styled'
import { bp } from '@helloextend/customers-ui'
import type { Claim, ServiceOrder } from '@helloextend/extend-api-client'
import { COLOR, Icon, Mail, Timeline, TimelineItem } from '@extend/zen'
import type { FC } from 'react'
import { V2 } from '@extend-services/service-orders'
interface RepairAcceptedServicerOwnedTimelineProps {
  serviceOrder?: ServiceOrder
  claim: Claim
}

export const RepairAcceptedServicerOwnedTimeline: FC<RepairAcceptedServicerOwnedTimelineProps> = ({
  claim,
  serviceOrder,
}) => {
  const getRepairText = (): string => {
    if (!serviceOrder || serviceOrder.status === 'created') {
      return 'We are currently working on assigning a repair order to a servicer. You will receive an email from us soon.'
    }

    const repairTextMap: Record<V2.Models.RepairFulfillmentMethod, string> = {
      cleaning_kit: 'We will be shipping you a cleaning kit to fix your stain shortly.',
      self_repair: 'We will be shipping you a part so that you can repair your product shortly.',
      carry_in: `You can bring your product in for repair at ${serviceOrder?.assignedServicer?.servicingLocation?.businessName}.`,
    }

    if (!serviceOrder.configurations?.repairFulfillmentMethod) return ''

    return repairTextMap[serviceOrder.configurations.repairFulfillmentMethod]
  }
  return (
    <Container data-cy="repair-accepted-servicer-owned-timeline">
      <Timeline>
        <TimelineItem
          label="Claim approved for repair"
          state="current"
          data-cy="claim-approved-timeline-item"
        />
      </Timeline>
      <ContentContainer>
        {serviceOrder?.assignedServicer?.businessName && (
          <ServicerTextContainer>
            <span>Servicer: </span>
            <ServicerText data-cy="servicer-name">
              {serviceOrder.assignedServicer.businessName}
            </ServicerText>
          </ServicerTextContainer>
        )}
        {serviceOrder?.serviceType === 'repair_depot' && (
          <MainText data-cy="repair-depot-text">
            Watch for the shipping label in your email. After you send the defective product, your
            servicer will start the repair process and contact you via email.
          </MainText>
        )}
        {serviceOrder?.serviceType === 'repair_onsite' && (
          <MainText data-cy="repair-onsite-text">
            Your servicer will contact you via email to arrange your on-site repair.
          </MainText>
        )}
        {claim.serviceType === 'repair' && (
          <MainText data-cy="repair-text">{getRepairText()}</MainText>
        )}
      </ContentContainer>
      <KeepEyeOnInboxContainer>
        <Icon
          icon={Mail}
          color={`var(--neutral-800, ${COLOR.NEUTRAL[800]});`}
          data-cy="sentiment-calm-icon"
        />
        <KeepEyeOnInboxText data-cy="keep-eye-on-inbox-text">
          Keep an eye on inbox!
        </KeepEyeOnInboxText>
      </KeepEyeOnInboxContainer>
    </Container>
  )
}

const Container = styled.div({
  width: '100%',
  background: `var(--white, ${COLOR.WHITE});`,
})

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 16,
  flex: '1 0 0',
  [bp.mobile]: {
    marginLeft: 20,
    marginBottom: 24,
  },
  [bp.desktop]: {
    marginLeft: 29,
    marginBottom: 16,
  },
})

const ServicerTextContainer = styled.div({
  color: `color: var(--text-emphasis-high-default, ${COLOR.NEUTRAL[1000]};`,
  fontFeatureSettings: `'clig' off, 'liga' off;`,
  fontFamily: 'Nunito Sans',
  lineHeight: '24px',
  [bp.mobile]: {
    fontSize: 15,
    fontWeight: 600,
  },
  [bp.desktop]: {
    fontSize: 16,
    fontWeight: 700,
  },
})

const ServicerText = styled.span({
  [bp.mobile]: {
    fontWeight: 600,
  },
  [bp.desktop]: {
    fontWeight: 400,
  },
})

const MainText = styled.div({
  color: `color: var(--text-emphasis-high-default, ${COLOR.NEUTRAL[1000]});`,
  fontFeatureSettings: `'clig' off, 'liga' off;`,
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  lineHeight: '24px',
  [bp.mobile]: {
    fontSize: 15,
  },
  [bp.desktop]: {
    fontSize: 16,
  },
})

const KeepEyeOnInboxContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--badge-border-radius, 4px);',
  borderRadius: 'var(--button-border-radius, 36px);',
  [bp.mobile]: {
    justifyContent: 'center',
  },
  [bp.desktop]: {
    justifyContent: 'flex-start',
    marginLeft: 29,
  },
})

const KeepEyeOnInboxText = styled.div({
  color: `var(--neutral-800, ${COLOR.NEUTRAL[800]});`,
  fontFamily: 'Nunito Sans',
  lineHeight: '24px',
  [bp.mobile]: {
    fontSize: 15,
    fontWeight: 600,
  },
  [bp.desktop]: {
    fontSize: 16,
    fontWeight: 700,
  },
})
